import axios from "axios";
import React from "react";

function Checkout() {
  const handleClick = async () => {
    console.log("Satın al butonuna tıklandı");
    try {
      const response = await axios.post(
        "https://api.cerebeye.io/payments/checkout/init",
        {
          locale: "tr",
          conversationId: "123456789",
          price: "1.0",
          basketId: "B67832",
          paymentGroup: "PRODUCT",
          buyer: {
            id: "BY789",
            name: "John",
            surname: "Doe",
            identityNumber: "74300864791",
            email: "email@email.com",
            gsmNumber: "+905350000000",
            registrationDate: "2013-04-21 15:12:09",
            lastLoginDate: "2015-10-05 12:43:35",
            registrationAddress:
              "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
            city: "Istanbul",
            country: "Turkey",
            zipCode: "34732",
            ip: "85.34.78.112"
          },
          shippingAddress: {
            address: "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
            zipCode: "34742",
            contactName: "Jane Doe",
            city: "Istanbul",
            country: "Turkey"
          },
          billingAddress: {
            address: "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
            zipCode: "34742",
            contactName: "Jane Doe",
            city: "Istanbul",
            country: "Turkey"
          },
          basketItems: [
            {
              id: "BI101",
              price: "0.3",
              name: "Binocular",
              category1: "Collectibles",
              category2: "Accessories",
              itemType: "PHYSICAL"
            },
            {
              id: "BI102",
              price: "0.5",
              name: "Game code",
              category1: "Game",
              category2: "Online Game Items",
              itemType: "VIRTUAL"
            },
            {
              id: "BI103",
              name: "Usb",
              price: "0.2",
              category1: "Electronics",
              category2: "Usb / Cable",
              itemType: "PHYSICAL"
            }
          ],
          enabledInstallments: [1, 2, 3, 6, 9],
          callbackUrl: "https://api.cerebeye.io/iyzico/callback",
          currency: "TRY",
          paidPrice: "1.2"
        },
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzMjQ3YjBlMy1hMzNjLTRlMDUtYjJhZC1hMzM5MWEyY2QzNjAiLCJpYXQiOjE3MTU5MzU5NjIsImV4cCI6MTcyMzcxMTk2Mn0.VUM1tzX-CRhJJpGnSEjC7dP4ap8rJSYHL6WEgp9tfw_36Lws5jnAsOVOu8Vm5OJMymAtk2Rj1CusTkC5NI_Bpyt0XaN8JJc3ceUT6Re46nBHu9uKwXmNLy6iyxYm_FEO1nvidADJ2SOfFx2Tp2MQV1IOZPEFaELvGe4nZhLP-b7gZjFW78gfMTS7snBgRM9pLdGPQsZD3L7XbWH0V6Y0bm2vTCq39lZyBv8GduftLFh5qmheaCBEXw9_wz8ppaOi9sV8Wmj8HvAzE_w6zJB3MyB2VJCpaHaFG2etI6Q97WkuzHIweH3L0b8nVdu79IboGBPpvidEfr1I8IN-xM5Adfg49ym_QoWYctdUHoR7SBaHvJZz6Isx8MrpZgzV06T-DdT6M4XSZ20pj84xhZSMKRbGUmwkMXMq76LambjZ3XW1vaCPfF0q6jKPHhc-p03ZLeUUCSDm-vhtg22THkp_yi7CDdK1uwKyqPL5Yp6ZycEkT2uWxQsz56AmqtM8ssYgmFsCgv8W3uNxixt4QRxQyTD8V375UjzW8E2oJz0QkP9Z34EM5xR8u7d-efeyYxnm7vl37Kragq5a7snxuTwPVvuGXOyaG1dZ8odXX5cg-mlZyaomWBdDxKsbckyjz-mMrASS51b9Qv6XmoQnfPaIq2bKwtvpwh8ejc929PY7A6I"
          }
        }
      );

      const json = response.data;
      console.log(json);

      if (json.status === "success") {
        localStorage.setItem("cfToken", json.token);
        window.location.href = json.paymentPageUrl;
      } else {
        alert("Ödeme işlemi başlatılamadı.");
      }
    } catch (error) {
      console.error("Ödeme işlemi başlatılamadı:", error);
    }
  };

  return (
    <div>
      Checkout
      <button onClick={handleClick}>Satın Al</button>
    </div>
  );
}

export default Checkout;
