import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const PaymentCallback = () => {
  //const navigate = useNavigate();

  //const token = localStorage.getItem("cfToken");

  // useEffect(() => {
  //   const token = localStorage.getItem("cfToken");

  //   if (token) {
  //     cfSorgulama(token);
  //   }
  // }, []);

  // const cfSorgulama = async (token) => {
  //   try {
  //     const response = await axios.post(
  //       "https://api.cerebeye.io/payments/checkout/retrieve",
  //       {
  //         token: token
  //       },
  //       {
  //         headers: {
  //           Authorization:
  //             "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzMjQ3YjBlMy1hMzNjLTRlMDUtYjJhZC1hMzM5MWEyY2QzNjAiLCJpYXQiOjE3MTU5MzU5NjIsImV4cCI6MTcyMzcxMTk2Mn0.VUM1tzX-CRhJJpGnSEjC7dP4ap8rJSYHL6WEgp9tfw_36Lws5jnAsOVOu8Vm5OJMymAtk2Rj1CusTkC5NI_Bpyt0XaN8JJc3ceUT6Re46nBHu9uKwXmNLy6iyxYm_FEO1nvidADJ2SOfFx2Tp2MQV1IOZPEFaELvGe4nZhLP-b7gZjFW78gfMTS7snBgRM9pLdGPQsZD3L7XbWH0V6Y0bm2vTCq39lZyBv8GduftLFh5qmheaCBEXw9_wz8ppaOi9sV8Wmj8HvAzE_w6zJB3MyB2VJCpaHaFG2etI6Q97WkuzHIweH3L0b8nVdu79IboGBPpvidEfr1I8IN-xM5Adfg49ym_QoWYctdUHoR7SBaHvJZz6Isx8MrpZgzV06T-DdT6M4XSZ20pj84xhZSMKRbGUmwkMXMq76LambjZ3XW1vaCPfF0q6jKPHhc-p03ZLeUUCSDm-vhtg22THkp_yi7CDdK1uwKyqPL5Yp6ZycEkT2uWxQsz56AmqtM8ssYgmFsCgv8W3uNxixt4QRxQyTD8V375UjzW8E2oJz0QkP9Z34EM5xR8u7d-efeyYxnm7vl37Kragq5a7snxuTwPVvuGXOyaG1dZ8odXX5cg-mlZyaomWBdDxKsbckyjz-mMrASS51b9Qv6XmoQnfPaIq2bKwtvpwh8ejc929PY7A6I"
  //         }
  //       }
  //     );

  //     const json = response.data;
  //     console.log("dönüş:", json);

  //     if (json.paymentStatus === "SUCCESS") {
  //       alert("Ödeme Başarılı!");
  //       navigate("/success");
  //     } else {
  //       alert("Ödeme Başarısız!");
  //       navigate("/failure");
  //     }
  //   } catch (error) {
  //     console.error("Ödeme durumu sorgulama hatası:", error);
  //     alert("Ödeme durumu sorgulama hatası.");
  //     navigate("/failure");
  //   }
  // };

  return <div>Ödeme Durumu Kontrol Ediliyor...</div>;
};

export default PaymentCallback;
