import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import PaymentCallback from "./PaymentCallback";
import Checkout from "./Checkout";
import Success from "./Success";
import Failure from "./Failure";
import Login from "./Login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AuthLogin from "./AuthLogin";

function App() {
  const clientId =
    "130121637199-comfhq86fr4b33ntqtgclcr2agqqelbf.apps.googleusercontent.com";

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Router>
        <Routes>
          <Route path="/" element={<Checkout />} />
          <Route path="/callback" element={<PaymentCallback />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/login" element={<Login />} />
          <Route path="/auth/login" element={<AuthLogin />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
