import axios from "axios";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

function AuthLogin() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const [res, setRes] = React.useState(null);
  const handleLogin = async () => {
    const response = await axios.post("https://api.cerebeye.io/auth/login", {
      email: email,
      password: password,
      token: token,
    });
    setRes(response.data);
    if (response.data.data.kioskId) {
      alert("Kiosk Login Success");
      setTimeout(() => {
        Navigate("/");
      }, 1500);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="text"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
      <p>{res}</p>
      <p>token:{token}</p>
    </div>
  );
}

export default AuthLogin;
