import axios from "axios";
import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

function Login() {
  const [qrCode, setQrCode] = React.useState(null);
  const [kiosk, setKiosk] = React.useState(null);
  const [intervalId, setIntervalId] = React.useState(null);
  const navigate = useNavigate();
  console.log("kiosk:", kiosk);

  useEffect(() => {
    if (kiosk) {
      const id = setInterval(() => checkAuthenticationForQr(kiosk), 1000);
      setIntervalId(id);
    }
    return () => clearInterval(intervalId);
  }, [kiosk]);

  const handleQRLogin = async () => {
    try {
      const response = await axios.get(
        "https://api.cerebeye.io/auth/generate-qr"
      );
      console.log(response.data);
      setKiosk(response.data.kioskId);
      setQrCode(response.data.qrCodeUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const checkAuthenticationForQr = async (kiosk) => {
    try {
      const response = await axios.post(
        "https://api.cerebeye.io/auth/kiosk-check",
        {
          kioskId: kiosk,
        }
      );

      console.log("response:", response.data);
      if (response.data.accessToken) {
        Cookies.set("access_token", response.data.accessToken, { expires: 7 });

        clearInterval(intervalId);

        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [intervalId]);

  const handleGoogleLogin = () => {
    window.location.href = "https://api.cerebeye.io/auth/google";
  };

  const handleSuccess = async (credentialResponse) => {
    console.log(credentialResponse);

    try {
      const response = await fetch(
        "https://api.cerebeye.io/auth/google/callback",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: credentialResponse.credential }),
        }
      );

      const data = await response.json();
      console.log("data:", data);
      // Daha sonra bu verileri kullanarak kullanıcı oturumunu yönetebilirsiniz
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleError = () => {
    console.log("Login Failed");
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {qrCode ? (
        <img src={qrCode} alt="QR Kod"></img>
      ) : (
        <button onClick={handleQRLogin}>QR ile Giriş Yap</button>
      )}
      <GoogleLogin onSuccess={handleSuccess} onError={handleError} />{" "}
      <button onClick={handleGoogleLogin}>Google ile Giriş</button>
    </div>
  );
}

export default Login;
